import Carousel from 'react-bootstrap/Carousel'
import slide1 from '../../images/Manage/IMG_3382.webp'
import slide2 from '../../images/Manage/IMG_4047.webp'
import slide3 from '../../images/Manage/IMG_4068.webp'
import slide4 from '../../images/Manage/IMG_4184.webp'

function CarouselMain() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img className="d-block w-100" src={slide1} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={slide2} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={slide3} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={slide4} alt="First slide" />
      </Carousel.Item>
    </Carousel>
  )
}

export default CarouselMain
